import { createQuery } from 'react-query-kit'

const useDownloadPdf = createQuery<Blob, string>({
  primaryKey: 'getPdfFile',
  queryFn: async ({ queryKey: [, variables] }) => {
    const response = await fetch(variables)
    return await response.blob()
  },
})

export default useDownloadPdf
