export type TDocumentState = {
  numPages?: number
  isError: boolean
  page: number
  scaleIndex: number
}

export type TPdfViewer = {
  url: string
  enableDownload?: boolean
}

export const ZOOM_INDEXES = [0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2, 2.5, 3, 4, 5]
