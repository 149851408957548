'use client'
import { cx } from 'class-variance-authority'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { ElementType, HTMLAttributeAnchorTarget, ReactNode, useEffect, useRef, useState } from 'react'
import { ChevronDown } from 'react-feather'
import { isSubpath } from '../../isSubpath'
import styles from './Sidebar.module.css'

export interface SidebarMenuProps {
  title: string
  Icon?: ElementType
  collapsible?: boolean
  href?: string
  children?: ReactNode
  isDefaultOpenCollapse?: boolean
  classes?: Partial<{ menuTitle: string }>
  target?: HTMLAttributeAnchorTarget
  level?: number
  onSetMenuOpened?: (menuTitle?: string) => void
  isCheckMenuAccess?: boolean
}

export default function SidebarMenu({
  isDefaultOpenCollapse,
  title,
  href,
  Icon,
  children,
  collapsible,
  classes,
  target,
  level,
  onSetMenuOpened,
  isCheckMenuAccess,
}: SidebarMenuProps) {
  const pathname = usePathname()
  const contentRef = useRef<HTMLUListElement>(null)
  const [isOpen, setIsOpen] = useState(isDefaultOpenCollapse)

  useEffect(() => {
    onSetMenuOpened?.(isOpen ? title : undefined)
  }, [isOpen])

  const content = (
    <span
      className={styles.menuLabel}
      onClick={collapsible ? () => setIsOpen(!isOpen) : undefined}
      data-collapsible={collapsible}
    >
      {Icon && <Icon className="shrink-0" />}
      <span className={cx(styles.menuTitle, classes?.menuTitle)}>{title}</span>
      {collapsible && (
        <ChevronDown
          size={24}
          strokeWidth={1.5}
          style={{ transform: isOpen && !isCheckMenuAccess ? 'rotate(0deg)' : 'rotate(180deg)' }}
        />
      )}
    </span>
  )

  return (
    <li className={styles.menu} data-active={!!href && isSubpath(href, pathname)} data-level={level}>
      {href ? (
        <Link href={href} target={target}>
          {content}
        </Link>
      ) : (
        content
      )}
      {children && (
        <ul
          className={styles.subList}
          style={{ maxHeight: isOpen && !isCheckMenuAccess ? undefined : 0 }}
          ref={contentRef}
        >
          {children}
        </ul>
      )}
    </li>
  )
}
