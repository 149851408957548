'use client'
import { HTMLProps } from 'react'
import { cx } from 'class-variance-authority'
import styles from './Sidebar.module.css'

export default function Sidebar(props: HTMLProps<HTMLElement>) {
  const { className, ...rest } = props
  return (
    <nav className={cx(styles.sidebar, className)} {...rest}>
      <ul>{props.children}</ul>
    </nav>
  )
}
