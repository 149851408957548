'use client'
import * as radixSlider from '@radix-ui/react-slider'
import { useState } from 'react'
import { cx } from 'class-variance-authority'

import styles from '../Slider.module.css'
import { TSliderDoubleThumbProps } from '../Slider.type'

const DoubleThumb = (props: TSliderDoubleThumbProps) => {
  type valueType = [number, number]
  const {
    numberIndicatorStyle = 'float',
    extractLabel,
    className,
    max = 100,
    defaultValue = [0, max],
    value,
    onValueChange,
    ...wrapperProps
  } = props

  const isControlled = value !== undefined
  const [values, setValues] = useState<valueType>(defaultValue)

  const handleChange = (newValues: valueType) => {
    setValues(newValues)
  }

  const percentagePosition: valueType = (isControlled ? value : values).map(
    (value: number) => (value * 100) / max,
  ) as valueType
  const adjustedPosition: valueType = (isControlled ? value : values).map(
    (value: number) => (value / max - 0.5) * 24,
  ) as valueType

  return (
    <radixSlider.Root
      className={cx(styles.SliderRoot, className)}
      defaultValue={defaultValue}
      onValueChange={(value: [number, number]) => {
        if (!isControlled) handleChange(value)
        onValueChange?.(value)
      }}
      value={isControlled ? value : undefined}
      data-type={numberIndicatorStyle}
      max={max}
      {...wrapperProps}
    >
      <radixSlider.Track className={styles.SliderTrack}>
        <radixSlider.Range className={styles.SliderRange} />
      </radixSlider.Track>
      <radixSlider.Thumb className={styles.SliderThumb} aria-label="Left" />
      <span
        className={styles.numberIndicatorContainer}
        style={{
          left: `calc(${percentagePosition?.[0]}% - ${adjustedPosition?.[0]}px)`,
        }}
      >
        <p className={styles.numberIndicator}>{extractLabel?.((isControlled ? value : values)[0])}</p>
      </span>
      <radixSlider.Thumb className={styles.SliderThumb} aria-label="Right" />
      <span
        className={styles.numberIndicatorContainer}
        style={{
          left: `calc(${percentagePosition?.[1]}% - ${adjustedPosition?.[1]}px)`,
        }}
      >
        <p className={styles.numberIndicator}>{extractLabel?.((isControlled ? value : values)[1])}</p>
      </span>
    </radixSlider.Root>
  )
}

export default DoubleThumb
