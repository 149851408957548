import type { Row } from "@tanstack/react-table"
import { flexRender } from "@tanstack/react-table"
import equal from "fast-deep-equal"
import React, { memo } from "react"
import { useDrag, useDrop } from "react-dnd"
import { ChevronDown, ChevronUp } from "react-feather"

const DND_ITEM_TYPE = "row"

interface DragableRowTypes {
  row: Row<object>
  index: number
  moveRow: (dragIndex: number, hoverIndex: number) => void
  isDragable: boolean | undefined
  rowCellClassname?: string[]
  customTableBodyStyles?: string
}

const DragableRow = ({
  row,
  index,
  moveRow,
  isDragable,
  rowCellClassname,
  customTableBodyStyles,
}: DragableRowTypes) => {
  const dropRef = React.useRef<HTMLTableRowElement>(null)
  const dragRef = React.useRef<HTMLTableDataCellElement>(null)

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item: { index: number }, monitor) {
      if (!dropRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = dropRef.current.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      if (clientOffset) {
        const hoverClientY = clientOffset?.y - hoverBoundingRect.top
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }
        moveRow(dragIndex, hoverIndex)
        item.index = hoverIndex
      }
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DND_ITEM_TYPE, index },
    type: DND_ITEM_TYPE,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1

  preview(drop(dropRef))
  drag(dragRef)

  return (
    <tr data-test="table-row-data" style={{ opacity }} ref={dropRef}>
      {isDragable ? (
        <td
          className="flex py-4 text-sm font-normal  "
          style={{ cursor: "pointer" }}
          ref={dragRef}
        >
          <div className="flex w-full flex-col items-center justify-center">
            <ChevronUp size={20} />
            <ChevronDown size={20} />
          </div>
        </td>
      ) : null}
      {row.getVisibleCells().map((cell, idx) => {
        return (
          <td
            data-test="table-cell-data"
            className={`${
              rowCellClassname?.[idx] ?? "py-4"
            } ${customTableBodyStyles} table-cell pr-4  text-sm font-normal first:pl-4`}
            key={cell.id}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        )
      })}
    </tr>
  )
}

export default memo(
  DragableRow,
  (prevProps, nextProps) =>
    prevProps.row.getIsSelected() !== nextProps.row.getIsSelected() &&
    equal(prevProps.row.original, nextProps.row.original) &&
    equal(prevProps.rowCellClassname, nextProps.rowCellClassname)
)
