import { TDateRangePickerPresetProps } from './DateRangePicker.type'
import styles from './DateRangePicker.module.css'
import { generatePresetDateRange, presetDateRangeOptions } from './utils'

function DateRangePickerPreset(props: TDateRangePickerPresetProps) {
  const { value, onChange } = props
  return (
    <div className={styles.dateRangePickerPreset}>
      {presetDateRangeOptions.map((option) => {
        return (
          <div
            key={option.value}
            data-selected={option.value === value}
            onClick={() => onChange(option.value, generatePresetDateRange(option.value))}
          >
            {option.label}
          </div>
        )
      })}
    </div>
  )
}

export default DateRangePickerPreset
