'use client'
import { TPinInputProps } from './PinInput.types'
import { usePinInput } from 'react-pin-input-hook'
import { useState } from 'react'
import styles from './PinInput.module.css'
import { cx } from 'class-variance-authority'

export default function PinInput({
  numberOfCharacters,
  color = 'neutral',
  helperText,
  informationText,
  wrapperProps = {},
  handleOnChange,
  ...rest
}: TPinInputProps) {
  const [values, setValues] = useState(Array(numberOfCharacters).fill(''))
  const { fields } = usePinInput({
    values,
    onChange(values) {
      setValues(values)
      handleOnChange && handleOnChange(values)
    },
    ...rest,
  })

  const { className: containerClassname, ...containerProps } = wrapperProps

  return (
    <div className={cx(styles.container, containerClassname)} {...containerProps}>
      <div className={styles.inputContainer}>
        {fields.map((field, index) => (
          <input className={styles.inputBox} key={index} {...field} placeholder="•" data-color={color} />
        ))}
      </div>
      {helperText && (
        <div className={styles.helperText} data-color={color}>
          {helperText}
        </div>
      )}
      {informationText && <div className={styles.informationText}>{informationText}</div>}
    </div>
  )
}
