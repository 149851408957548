import { FLAG_DIET_COOKIE } from "./flag"

export const GTP_TYK_WS_URL = (
  process.env.NEXT_PUBLIC_CMS_FEDERATED_TYK_URL ||
  process.env.NEXT_PUBLIC_FEDERATED_TYK_URL ||
  ""
).replace("https", "wss")

/**
 * Note: Digunakan untuk mengarahkan websocket ke pages api 'api/getUploadStatus'
 */
export const GTP_TYK_WS_URL_V2 = (
  FLAG_DIET_COOKIE
    ? `${process.env.NEXTAUTH_URL}graphql` || ""
    : process.env.NEXT_PUBLIC_CMS_FEDERATED_TYK_URL ||
      process.env.NEXT_PUBLIC_FEDERATED_TYK_URL ||
      ""
).replace("https", "wss")
