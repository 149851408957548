"use client"

import { TGetSignedUrl, TSocketMessage } from "../types"
import { useEffect, useRef, useState } from "react"
import io, { type Socket } from "socket.io-client"

export const FINISH_STATUS = [
  "UPLOAD_VALIDATION_FAILED",
  "UPLOAD_VIRUS_DETECTED",
  "UPLOAD_SUCCESS",
]

const SCANNING_STATUS = {
  INITIATE_UPLOAD: 92,
  UPLOAD_ON_METADATA_VALIDATION: 95,
  UPLOAD_ON_MALWARE_SCANNING: 98,
}

type TTimeout = {
  /**
   * timeout duration in ms
   */
  duration: number
  message: string
}

type TCreateSocket = {
  file?: File
  baseURl: string
  signedUrl: TGetSignedUrl
}

type TUseUploadProps = {
  onError?: (err: string) => void
  onSuccessUpload?: (signedUrl: TGetSignedUrl, file?: File) => void
  errorMessage?: {
    validationFailed?: string
    virusDetected?: string
  }
  timeout?: TTimeout
  updatePercentage?: (percentage: number) => void
}

const prefetchSocketApi = async (baseURl: string) => {
  try {
    const response = await fetch(
      `${baseURl}api/getUploadStatus?EIO=4&transport=polling`,
      { method: "GET" }
    )

    if (!response.ok) {
      throw new Error(response.statusText)
    }

    return true
  } catch (err) {
    return false
  }
}

const useGetUploadStatusV2 = ({
  onError,
  errorMessage,
  onSuccessUpload,
  updatePercentage,
}: TUseUploadProps) => {
  const websocket = useRef<Socket>()
  const [percentage, setPercentage] = useState(0)
  const [standbyCount, setStandbyCount] = useState(0)

  const closeSocket = () => {
    if (websocket.current) {
      websocket.current?.close()
      websocket.current = undefined
    }
  }

  const fullCloseWebsocket = () => {
    websocket.current?.emit("clientAction", "CLOSE_SOCKET")
    websocket.current?.close()
    websocket.current = undefined
  }

  const handleError = (err: string) => {
    if (onError) {
      onError(
        err || "Telah terjadi kesalahan, mohon ulangi dalam beberapa saat lagi"
      )
    }
    setPercentage(0)
    updatePercentage && updatePercentage(0)
    fullCloseWebsocket()
  }

  const createSocket = async ({ baseURl, signedUrl, file }: TCreateSocket) => {
    const prefetchStatus = await prefetchSocketApi(baseURl)
    if (!prefetchStatus) return
    websocket.current = io(baseURl, {
      path: "/api/getUploadStatus",
      transports: ["websocket"],
      withCredentials: true,
      query: {
        clientId: `${Date.now()}-${Math.random().toString(36).substring(2, 9)}`,
      },
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
    })

    websocket.current.on("connect", () => {
      websocket.current?.emit("clientAction", "CREATE_SOCKET")
    })

    websocket.current.on("closeSocket", () => {
      fullCloseWebsocket()
    })

    websocket.current.on("errorSocket", (errorMsg: string) => {
      fullCloseWebsocket()
    })

    websocket.current.on("backendMessage", (data: string) => {
      const dataResponse = JSON.parse(data) as TSocketMessage
      const status = dataResponse?.payload?.data?.getUploadStatus || ""

      if (dataResponse.type === "connection_ack") {
        websocket.current?.emit("clientRequest", signedUrl.token)
        return
      }

      /**
       * "ka" type is response backend to keep the connection alive during idle periods
       * If we receive 5 consecutive "ka" messages without any getUploadStatus updates, it indicates prolonged inactivity and will close automatically
       */
      if ((dataResponse.type = "ka") && standbyCount < 5 && !status) {
        setStandbyCount((prev) => prev + 1)
      }

      if (standbyCount > 5) {
        setStandbyCount(0)
        fullCloseWebsocket()
      }

      if (!FINISH_STATUS.includes(status)) {
        const tempPercentage =
          SCANNING_STATUS?.[status as keyof typeof SCANNING_STATUS]
        setPercentage((prev) => tempPercentage || prev)
        updatePercentage && updatePercentage(tempPercentage || percentage)
        return
      }
      switch (status) {
        case FINISH_STATUS[2]:
          setPercentage(0)
          updatePercentage && updatePercentage(0)
          onSuccessUpload && onSuccessUpload(signedUrl, file)
          break
        case FINISH_STATUS[1]:
          setPercentage(0)
          updatePercentage && updatePercentage(0)
          handleError(errorMessage?.virusDetected ?? "")
          break
        case FINISH_STATUS[0]:
          setPercentage(0)
          updatePercentage && updatePercentage(0)
          handleError(errorMessage?.validationFailed ?? "")
          break
        default:
          handleError("")
          break
      }

      fullCloseWebsocket()
    })
  }

  const getUploadStatus = (props: TCreateSocket) => {
    createSocket(props)
    setStandbyCount(0)
  }

  useEffect(() => {
    window.addEventListener("beforeunload", closeSocket)
    return () => {
      window.addEventListener("beforeunload", closeSocket)
    }
  }, [])

  return {
    percentage,
    getUploadStatus,
    closeSocket: fullCloseWebsocket,
  }
}

export default useGetUploadStatusV2
