'use client'
import { cx } from 'class-variance-authority'
import { TIndeterminateCheckboxProps } from './IndeterminateCheckbox.type'
import { Check, Minus } from 'react-feather'
import { forwardRef, useEffect, useRef } from 'react'
import styles from '../Checkbox/Checkbox.module.css'
import useForkRef from 'shared-utils/src/useForkRef'

const IndeterminateCheckbox = forwardRef<HTMLInputElement, TIndeterminateCheckboxProps>(function (props, ref) {
  const { disabled = false, value, className, children, size = 'large', wrapperProps, onChange, ...rest } = props

  const inputRef = useRef<HTMLInputElement>()
  const handleRef = useForkRef(inputRef, ref)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = value === 'indeterminate'
    }
  }, [value])

  return (
    <label {...wrapperProps} className={cx(styles.wrapper, wrapperProps?.className)} data-size={size}>
      <input
        {...rest}
        checked={Boolean(value)}
        onChange={() => {
          if (value === 'indeterminate') onChange?.(true)
          else onChange?.(!value)
        }}
        ref={handleRef}
        disabled={disabled}
        readOnly
        type="checkbox"
        className={cx(styles.input, className)}
      />
      <span className={styles.icon}>
        {value === 'indeterminate' && <Minus size={size === 'large' ? 20 : 14} />}
        {value === true && <Check size={size === 'large' ? 20 : 14} />}
      </span>
      {children && <span className={styles.caption}>{children}</span>}
    </label>
  )
})

export default IndeterminateCheckbox
