'use client'
import { cx } from 'class-variance-authority'
import { TAnchorTextProps } from '.'
import styles from './AnchorText.module.css'

function AnchorText(props: TAnchorTextProps) {
  const {
    color = 'secondary',
    size = 'medium',
    iconLeft: IconLeft,
    iconRight: IconRight,
    children,
    className,
    disabled = false,
    ...rest
  } = props

  return (
    <a
      {...rest}
      data-size={size}
      data-color={color}
      data-disabled={disabled}
      className={cx(styles.anchorText, className)}
      tabIndex={0}
    >
      {IconLeft && <IconLeft className={styles.iconLeft} />}
      {children}
      {IconRight && <IconRight className={styles.iconRight} />}
    </a>
  )
}

export default AnchorText
