export const textAreaStyle = {
  base: `
    outline-none
    text-caption-lg-regular
    placeholder-tertiary200
    relative
    inline-flex
    items-center
    w-full
    px-2
    py-1
    box-border
    text-tertiary500
    cursor-text
    w-full
    resize-y

    hover:border-tertiary200

    focus:border-tertiary200
    focus:shadow-0

    disabled:border-tertiary200
    disabled:cursor-not-allowed
    disabled:bg-tertiary25
    disabled:text-tertiary200
  `,
  outline: `
    rounded-4
    border
    border-solid
    border-tertiary50
  `,
  inline: `
    bg-tertiary25
    rounded-2
    border-t-0
    border-l-0
    border-r-0
    border-b-2
    border-solid
    border-tertiary200
  `,
}
