'use client'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { isSubpath } from '../../isSubpath'
import styles from './Sidebar.module.css'
import { HTMLAttributeAnchorTarget, MouseEvent } from 'react'
import { cx } from 'class-variance-authority'

export interface SidebarSubmenuProps {
  title: string
  href: string
  badge?: string
  target?: HTMLAttributeAnchorTarget
  level?: number
  disabled?: boolean
  className?: string
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
}

export default function SidebarSubmenu({
  title,
  href,
  badge,
  target,
  level,
  disabled,
  className,
  onClick,
}: SidebarSubmenuProps) {
  const pathname = usePathname()
  const disableProps = disabled
    ? {
        onClick: (e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault(),
        className: 'cursor-events-none cursor-not-allowed !text-[#818996] hover:!text-[#818996]',
      }
    : null

  return (
    <li className={cx(styles.submenu, className)} data-active={isSubpath(href, pathname)} data-level={level}>
      <Link href={href} target={target} {...disableProps} onClick={!disabled ? onClick : disableProps?.onClick}>
        <span className={styles.label}>{title}</span>
        {badge && <span className={styles.badge}>{badge}</span>}
      </Link>
    </li>
  )
}
