import { DropdownProps } from 'react-day-picker'
import Select, { MultiValue, SingleValue, TSelectOption } from '../Select'
import { ChangeEvent, useMemo } from 'react'

type TChildren = {
  props: {
    value: string
    children: string
  }
}

const controlStyle = {
  border: 'none !important',
  padding: '8px 4px !important',
  fontSize: '16px !important',
  fontWeight: '700 !important',
}

const SelectComponent = (props: DropdownProps) => {
  const { name, value, onChange, children } = props

  const tempOptions: TSelectOption[] = (children as TChildren[])?.map((child) => {
    return {
      value: child.props.value,
      label: child.props.children,
    }
  })
  const handleOnChange = (newValue: MultiValue<TSelectOption> | SingleValue<TSelectOption>) => {
    const dataChange = {
      target: {
        value: (newValue as TSelectOption)?.value,
      },
    } as ChangeEvent<HTMLSelectElement>
    onChange && onChange(dataChange)
  }

  const containerStyle = {
    width: name === 'years' ? '70px' : '120px',
    zIndex: 100,
  }

  const options = useMemo(() => {
    // if the its years dropdown, sort the options descending
    if (name === 'years') {
      return tempOptions.sort((a, b) => (parseInt(a.label) < parseInt(b.label) ? 1 : -1))
    }
    return tempOptions
  }, [name]) // prevent from sorting options on every rerender

  return (
    <div className="flex">
      <Select
        name={name}
        id={name}
        isSearchable={false}
        onChange={handleOnChange}
        value={options?.find((option) => option.value === value)}
        options={options}
        styles={{
          control: (base) => ({
            ...base,
            ...controlStyle,
          }),
          container: (base) => ({
            ...base,
            ...containerStyle,
          }),
        }}
      />
    </div>
  )
}

export default SelectComponent
