"use client"

import { useEffect, useState } from "react"

const ensurePositive = (value: number) => (value > 0 ? value : 0)

export default function useCountdown(
  targetDate: number,
  refreshRate: number = 1000
) {
  const countDownDate = targetDate

  const [countDown, setCountDown] = useState<number>(
    countDownDate - new Date().getTime()
  )

  const days = Math.floor(countDown / (1000 * 60 * 60 * 24)) || 0
  const hours =
    Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) || 0
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60)) || 0
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000) || 0

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime())
    }, refreshRate)

    return () => clearInterval(interval)
  }, [countDownDate])

  return {
    days: ensurePositive(days),
    hours: ensurePositive(hours),
    minutes: ensurePositive(minutes),
    seconds: ensurePositive(seconds),
  }
}
