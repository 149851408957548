'use client'

import { Item } from '@radix-ui/react-dropdown-menu'
import { cx } from 'class-variance-authority'

import { TDropdownItemProps } from './DropdownItem.type'

import styles from '../Dropdown/Dropdown.module.css'

const DropdownItem = ({ children, classes, id, ...rest }: TDropdownItemProps) => {
  return (
    <Item className={cx(styles.dropdownItemContainer, classes?.container)} id={id} {...rest}>
      <div className={cx(styles.dropdownItemContent, classes?.content)}>{children}</div>
    </Item>
  )
}

export default DropdownItem
