'use client'
import {
  baseIconStyle,
  addonLeftAddonRightStyle,
  wrapperStyle,
  wrapperStyleSize,
  addonLeftStyle,
  borderModifierStyle,
  addonRightStyle,
  labelStyle,
  captionStyle,
} from '../TextField'

import { forwardRef, useRef } from 'react'
import { cx } from 'class-variance-authority'
import { X } from 'react-feather'
import { TRawTextFieldProps } from './RawTextField.type'

const RawTextField = forwardRef<HTMLInputElement, TRawTextFieldProps>(function (props) {
  const {
    variant = 'normal',
    iconLeft: IconLeft,
    iconRight: IconRight,
    addonLeft,
    addonRight,
    disabled = false,
    size = 'medium',
    wrapperProps,
    fieldType = 'outline',
    label,
    caption,
    clearable,
    captionClassName,
    captionIcon,
    children,
  } = props

  const inputRef = useRef<HTMLInputElement>()

  return (
    <label {...wrapperProps}>
      {label && <div className={labelStyle}>{label}</div>}
      <div
        className={cx(
          wrapperStyle.base,
          fieldType === 'inline' && wrapperStyle.inline,
          wrapperStyleSize[size as keyof typeof wrapperStyleSize],
          Boolean(addonLeft) && 'pl-0',
          Boolean(addonRight) && 'pr-0',
          disabled && '!cursor-not-allowed bg-tertiary25 [&_*]:!text-tertiary200',
          wrapperProps?.className,
        )}
      >
        {addonLeft && (
          <span
            data-element="addon-left"
            className={cx(
              addonLeftAddonRightStyle.base,
              addonLeftStyle.base,
              fieldType === 'outline' && [addonLeftAddonRightStyle.outline, addonLeftStyle.outline],
              fieldType === 'inline' && [addonLeftAddonRightStyle.inline, addonLeftStyle.inline],
            )}
          >
            {addonLeft}
          </span>
        )}

        {IconLeft && <IconLeft className={cx(baseIconStyle, 'mr-4')} />}
        {children}
        <div
          className={cx(
            borderModifierStyle.base,
            fieldType === 'outline' && borderModifierStyle.outline,
            fieldType === 'inline' && borderModifierStyle.inline,
            variant === 'success' && !disabled && '!border-success500',
            variant === 'error' && !disabled && '!border-error500',
          )}
        />
        {clearable && (
          <X
            className={cx(baseIconStyle, 'ml-4 cursor-pointer')}
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.value = ''
                inputRef.current.dispatchEvent(
                  new Event('input', {
                    bubbles: true,
                    cancelable: true,
                  }),
                )
              }
            }}
          />
        )}
        {IconRight && !clearable && <IconRight className={cx(baseIconStyle, 'ml-4')} />}
        {addonRight && (
          <span
            data-element="addon-right"
            className={cx(
              addonLeftAddonRightStyle.base,
              addonRightStyle.base,
              fieldType === 'outline' && [addonLeftAddonRightStyle.outline, addonRightStyle.outline],
              fieldType === 'inline' && [addonLeftAddonRightStyle.inline, addonRightStyle.inline],
            )}
          >
            {addonRight}
          </span>
        )}
      </div>
      {caption && (
        <div className={cx(Boolean(captionIcon) && 'flex flex-nowrap items-center')}>
          {!!captionIcon && <div className="mr-1 mt-2 h-3 w-3 shrink-0">{captionIcon}</div>}
          <div
            className={cx(
              captionClassName,
              captionStyle,
              variant === 'error' && 'text-error500',
              variant === 'success' && 'text-success500',
              variant === 'normal' && 'text-tertiary300',
              Boolean(captionIcon) && 'flex-grow',
            )}
          >
            {caption}
          </div>
        </div>
      )}
    </label>
  )
})

export default RawTextField
