"use client"

import { useState, useEffect, useCallback } from "react"

interface UseWindowFocusReturn {
  isFocused: boolean
  checkFocus: () => boolean
}

export interface UseWindowFocusOptions {
  initialState?: boolean
  onFocusChange?: (isFocused: boolean) => void
}

export const useWindowFocus = ({
  initialState,
  onFocusChange,
}: UseWindowFocusOptions): UseWindowFocusReturn => {
  const [isFocused, setIsFocused] = useState(initialState ?? true)

  const checkFocus = useCallback(() => {
    return document.hasFocus()
  }, [])

  const handleVisibilityChange = () => {
    const isVisible = document.visibilityState === "visible"
    setIsFocused(isVisible && checkFocus())
  }

  const handleFocus = () => {
    setIsFocused(true)
    onFocusChange && onFocusChange(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
    onFocusChange && onFocusChange(false)
  }

  useEffect(() => {
    // Initial check
    setIsFocused(checkFocus())

    // Add event listeners
    window.addEventListener("focus", handleFocus)
    window.addEventListener("blur", handleBlur)
    document.addEventListener("visibilitychange", handleVisibilityChange)

    // Cleanup
    return () => {
      window.removeEventListener("focus", handleFocus)
      window.removeEventListener("blur", handleBlur)
      document.removeEventListener("visibilitychange", handleVisibilityChange)
    }
  }, [checkFocus])

  return { isFocused, checkFocus }
}

export default useWindowFocus
