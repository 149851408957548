'use client'

import type { ColumnDef, RowSelectionState, Table, Row } from '@tanstack/react-table'
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table'
import update from 'immutability-helper'
import { useEffect, useState, useMemo } from 'react'
import { ChevronDown } from 'react-feather'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { IndeterminateCheckbox } from './CheckBoxComponent'
import DragableRow from './DragableRow'
import Button from '../Button'
import Dropdown from '../Dropdown'
import DropdownItem from '../DropdownItem'
import Pagination from '../Pagination'
import type { GenericDataTypes, SelectedIndexTypes, TableComponentProps } from './TableComponent.type'
import Spinner from '../Spinner'

const TableComponent = <DataTypes,>({
  isSelectionEnabled,
  isMultiSelectionEnabled = false,
  isDragable,
  data,
  columns,
  rowSelection = [],
  onSelectedRowsChange,
  pageIndex = 1,
  pageSize = 10,
  selectedInputType = 'checkbox',
  totalData = 10,
  rowCellClassname,
  customTableWrapperStyles,
  customTableHeaderStyles,
  customTableBodyStyles,
  hidePagination,
  hideDropdownEntry,
  customTablePaginationContainerStyles,
  onClickPage,
  onChangeEntries,
  isError,
  isLoading,
  onRefetch,
  showFetchingProcess,
}: TableComponentProps<DataTypes>) => {
  const [records, setRecords] = useState(data)

  let excludedRowSelection: DataTypes[] = rowSelection
  const selectedItems: SelectedIndexTypes = {}

  const getID = (value?: GenericDataTypes<object>) => {
    if (value?.specificationId) return value?.specificationId
    if (value?.code) return value?.code
    return value?.id ?? ''
  }

  if (isSelectionEnabled) {
    data.forEach((value, index) => {
      if (value && rowSelection && rowSelection?.length > 0) {
        const indexValue = rowSelection?.indexOf(getID(value) as DataTypes)
        if (indexValue > -1) {
          selectedItems[index] = true
        }
        excludedRowSelection = excludedRowSelection.filter((item) => item !== getID(value))
      }
    })
  }

  const [rwSelection, setRwSelection] = useState<RowSelectionState>(selectedItems)

  const renderCheckboxHeader = (table: Table<object>) => {
    return isMultiSelectionEnabled ? (
      <div className="flex justify-center">
        <IndeterminateCheckbox
          {...{
            type: selectedInputType,
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      </div>
    ) : null
  }

  const renderCellHeader = (row: Row<object>) => {
    return (
      <div className="flex justify-center">
        <IndeterminateCheckbox
          {...{
            type: selectedInputType,
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    )
  }

  const checkBoxHeader = useMemo<ColumnDef<object, unknown>[]>(
    () => [
      {
        id: 'select',
        size: 5,
        minSize: 5,
        header: ({ table }) => renderCheckboxHeader(table),
        cell: ({ row }) => renderCellHeader(row),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const coloumnChecked = useMemo(() => {
    const isCheckedColoumn = isSelectionEnabled ? checkBoxHeader : []
    return isCheckedColoumn
  }, [checkBoxHeader, isSelectionEnabled])

  const table = useReactTable({
    data: records,
    columns: [...coloumnChecked, ...columns] as ColumnDef<object, unknown>[],
    state: {
      rowSelection: rwSelection,
      pagination: {
        pageIndex,
        pageSize,
      },
    },
    pageCount: Math.ceil(totalData / pageSize) ?? -1,
    enableRowSelection: isSelectionEnabled,
    enableMultiRowSelection: isMultiSelectionEnabled,
    onRowSelectionChange: setRwSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: Boolean(pageIndex && pageSize),
  })

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const dragRecord = records[dragIndex]
    dragRecord &&
      setRecords(
        update(records, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRecord],
          ],
        }),
      )
  }

  const handleClickPage = (page: number) => {
    table.setPageIndex(page)
    onClickPage && onClickPage(page)
  }

  const handleChangeEntries = (page: number) => {
    table.setPageSize(page)
    onChangeEntries && onChangeEntries(page)
  }

  const PAGE_SIZE = [10, 25, 50]

  useEffect(() => {
    setRecords(data)
  }, [data])

  useEffect(() => {
    if (isSelectionEnabled && rwSelection) {
      const selectedRowData: string[] =
        table.getSelectedRowModel().flatRows.map((row) => {
          return row?.original ? getID(row.original as GenericDataTypes<object>) : ''
        }) || []
      if (onSelectedRowsChange && selectedRowData) {
        onSelectedRowsChange(excludedRowSelection.concat(selectedRowData as DataTypes))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rwSelection])

  const rows = table.getRowModel().rows
  const isDataNotReceived = isLoading || isError

  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        <table data-test="table" className={customTableWrapperStyles ?? 'w-full max-w-full border border-[#E2E4E8]'}>
          <thead data-test="table-head" className="max-w-full">
            {table
              ? table.getHeaderGroups().map((headerGroup) => (
                  <tr data-test="table-row" key={headerGroup.id} className="max-w-full">
                    {isDragable && <th className="w-[5%] bg-tertiary25"></th>}
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          style={{
                            width: header.column.columnDef.size ? `${header.column.columnDef.size}%` : header.getSize(),
                            ...(header.column.columnDef.size && {
                              maxWidth: `${header.column.columnDef.size}%`,
                            }),
                          }}
                          className={
                            customTableHeaderStyles ??
                            'bg-tertiary25 py-4 pr-4 text-left text-sm text-tertiary500 first:pl-4'
                          }
                          key={header.id}
                          colSpan={header.colSpan}
                          data-test="table-cell-head"
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </th>
                      )
                    })}
                  </tr>
                ))
              : null}
          </thead>
          <tbody
            data-test="table-body"
            className={customTableBodyStyles ?? '[&>*:nth-child(even)]:bg-tertiary25 [&>*:nth-child(odd)]:bg-[#fff]'}
          >
            {table
              ? rows.map((row, index) => {
                  return (
                    <DragableRow
                      key={row.id}
                      row={row}
                      index={index}
                      moveRow={moveRow}
                      isDragable={isDragable}
                      rowCellClassname={rowCellClassname}
                      customTableBodyStyles={customTableBodyStyles}
                    />
                  )
                })
              : null}
          </tbody>
        </table>

        {showFetchingProcess && (
          <>
            {isDataNotReceived && (
              <div className="flex h-20 items-center justify-center border border-tertiary25">
                {isLoading && <Spinner size="small" />}
                {isError && (
                  <div className="flex-col items-center justify-center space-y-4 text-center">
                    <div className="text-caption-lg-regular text-tertiary300">Gagal Memuat Data</div>
                    {onRefetch && (
                      <Button
                        variant="outline2"
                        className="text-caption-sm-semibold"
                        onClick={onRefetch}
                        id="btn-refetch-table-data"
                      >
                        Muat Ulang
                      </Button>
                    )}
                  </div>
                )}
              </div>
            )}

            {!isDataNotReceived && !rows.length && (
              <div className="flex h-20 items-center justify-center border border-tertiary25">
                <p className="text-caption-lg-regular text-tertiary300">Tidak ada data</p>
              </div>
            )}
          </>
        )}

        {!hidePagination && !isDataNotReceived && (
          <div className={customTablePaginationContainerStyles ?? 'flex items-center justify-between p-4'}>
            <Pagination
              currentPage={table.getState().pagination.pageIndex}
              numberOfPages={table.getPageCount()}
              onPageChange={handleClickPage}
            />
            <div className="flex items-center justify-center">
              {!hideDropdownEntry && (
                <Dropdown
                  variant="main"
                  className="!min-w-fit"
                  trigger={
                    <Button variant="soft" IconRight={ChevronDown}>
                      {`${table.getState().pagination.pageSize} Data`}
                    </Button>
                  }
                >
                  {PAGE_SIZE.map((item, idx) => (
                    <DropdownItem
                      key={`dropdown-entry-${item}`}
                      onClick={() => handleChangeEntries(item)}
                      id={`list-entries-show-data-${idx}`}
                      classes={{
                        content: 'cursor-pointer',
                      }}
                    >
                      {item}
                    </DropdownItem>
                  ))}
                </Dropdown>
              )}
              <p className="px-4 text-sm font-normal text-tertiary300">Total {totalData.toLocaleString('id')}</p>
            </div>
          </div>
        )}
      </DndProvider>
    </div>
  )
}

export default TableComponent
