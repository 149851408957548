'use client'

import { forwardRef, useRef } from 'react'
import { cx } from 'class-variance-authority'
import useForkRef from 'shared-utils/src/useForkRef'
import { NumericFormat } from 'react-number-format'
import RawTextField from '../RawTextField/RawTextField'
import { inputStyle } from '../TextField'
import { TNumericFieldProps } from './NumericField.type'

const NumericField = forwardRef<HTMLInputElement, TNumericFieldProps>(function (props, ref) {
  const {
    variant = 'normal',
    iconLeft: IconLeft,
    iconRight: IconRight,
    addonLeft,
    addonRight,
    disabled = false,
    size = 'medium',
    className,
    wrapperProps,
    fieldType = 'outline',
    label,
    caption,
    clearable,
    captionClassName,
    captionIcon,
    ...rest
  } = props
  const inputRef = useRef<HTMLInputElement>()
  const handleRef = useForkRef(inputRef, ref)

  return (
    <RawTextField
      variant={variant}
      iconLeft={IconLeft}
      iconRight={IconRight}
      addonLeft={addonLeft}
      addonRight={addonRight}
      disabled={disabled}
      size={size}
      wrapperProps={wrapperProps}
      fieldType={fieldType}
      label={label}
      caption={caption}
      clearable={clearable}
      captionClassName={captionClassName}
      captionIcon={captionIcon}
      ref={handleRef}
    >
      <NumericFormat {...rest} getInputRef={handleRef} className={cx(inputStyle, className)} disabled={disabled} />
    </RawTextField>
  )
})

export default NumericField
