"use client"
import { useState, useEffect } from "react"

function useDebounce<T>(
  value: T,
  delay: number,
  callbackFn?: (debouncedValue: T) => void
): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value)
      callbackFn?.(value)
    }, delay)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}

export default useDebounce
