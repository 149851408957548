import { cx } from 'class-variance-authority'

const ItemPicker = ({
  item,
  onClick,
  tipe,
  hoursValue,
  minuteValue,
  refItem,
  disabled,
}: {
  item: string
  onClick: () => void
  tipe: 'hour' | 'minute'
  refItem?: React.RefObject<HTMLDivElement>
  hoursValue?: string
  minuteValue?: string
  disabled?: boolean
}) => {
  const hourActiveClass = item === hoursValue ? 'bg-tertiary500 text-primary25' : 'text-tertiary500'
  const minuteActiveClass = item === minuteValue ? 'bg-tertiary500 text-primary25' : 'text-tertiary500'

  return (
    <div
      ref={refItem}
      className={cx(tipe === 'hour' ? 'flex justify-end' : '', 'snap-start')}
      onClick={() => !disabled && onClick()}
    >
      <p
        className={cx(
          'w-fit cursor-pointer rounded-full p-3 text-sm',
          tipe === 'hour' ? hourActiveClass : minuteActiveClass,
          disabled ? 'bg-tertiary100' : 'hover:bg-tertiary200',
        )}
      >
        {item}
      </p>
    </div>
  )
}

export default ItemPicker
