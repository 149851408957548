'use client'
import { cx } from 'class-variance-authority'
import { useState } from 'react'
import { ArrowRight, ArrowLeft } from 'react-feather'

import Button from '../Button'

import styles from './Carousel.module.css'
import { TCarouselProps } from './Carousel.type'

function Carousel(props: TCarouselProps) {
  const { items, page, onPageChange } = props
  const isControlled = page !== undefined

  const [carouselPage, setCarouselPage] = useState<number>(0)

  const nextPage = () => {
    const curPage = isControlled ? page : carouselPage
    onPageChange?.((curPage + 1) % items.length)
    if (!isControlled) setCarouselPage((curPage + 1) % items.length)
  }
  const prevPage = () => {
    const curPage = isControlled ? page : carouselPage
    onPageChange?.((curPage - 1 + items.length) % items.length)
    if (!isControlled) setCarouselPage((curPage - 1 + items.length) % items.length)
  }
  const jumpToPage = (index: number) => {
    onPageChange?.(index)
    if (!isControlled) setCarouselPage(index)
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.imageViewport}>
        <Button IconLeft={ArrowLeft} variant="outline2" className={styles.prevButton} onClick={prevPage} />
        <Button IconLeft={ArrowRight} variant="outline2" className={styles.nextButton} onClick={nextPage} />
        <div
          className={styles.imageCollection}
          style={{
            width: `${items.length * 100}%`,
            transform: `translateX(-${(isControlled ? page : carouselPage) * (100 / items.length)}%)`,
          }}
        >
          {items.map(({ image }, i) => (
            <img className={styles.carouselImage} src={image} key={i} />
          ))}
        </div>
        <div className={styles.pageIndicator}>
          {items.map((_, i) => (
            <div
              className={cx(
                styles.pageIndicatorItem,
                (isControlled ? page : carouselPage) === i && styles.pageIndicatorItemActive,
              )}
              onClick={() => jumpToPage(i)}
            />
          ))}
        </div>
      </div>
      <div>
        <h1 className={styles.title}>{items?.[isControlled ? page : carouselPage]?.title}</h1>
        <p className={styles.subtitle}>{items?.[isControlled ? page : carouselPage]?.subtitle}</p>
      </div>
    </div>
  )
}

export default Carousel
