import { MouseEventHandler, ElementType } from 'react'
import { cx } from 'class-variance-authority'

type TToggleProps = {
  icon: ElementType
  isActive: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
}

const Toggle = ({ icon: Icon, isActive, onClick }: TToggleProps) => (
  <button type="button" onClick={onClick} className={cx('p-1', isActive && 'rounded-2 bg-tertiary700 text-tertiary25')}>
    <Icon size={16} />
  </button>
)

export default Toggle
