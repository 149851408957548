'use client'
import type React from 'react'
import { Copy } from 'react-feather'
import useCopy from '@react-hook/copy'

export type TCopyableTextProps = {
  copyText: string
  children: React.ReactNode
}

const CopyableText: React.FC<TCopyableTextProps> = (props) => {
  const { children, copyText } = props

  const { copy } = useCopy(copyText)

  return (
    <span
      className="
        inline-flex
        cursor-pointer
        text-sm
        text-secondary500
      "
      onClick={copy as () => void}
    >
      {children}
      <Copy size={20} className="ml-2" />
    </span>
  )
}

export default CopyableText
