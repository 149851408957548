"use client"

import { useEffect, useRef, DependencyList } from "react"
import isEqual from "fast-deep-equal"

function useDeepCompareEffect<T extends DependencyList>(
  callback: () => void | (() => void),
  dependencies: T
) {
  const currentDependenciesRef = useRef<T>()

  if (!isEqual(currentDependenciesRef.current, dependencies)) {
    currentDependenciesRef.current = dependencies
  }

  useEffect(() => {
    return callback()
  }, [currentDependenciesRef.current])
}

export default useDeepCompareEffect
