'use client'

import { ReactNode } from 'react'
import { LinkPaginationProps } from './LinkPagination.type'
import styles from './LinkPagination.module.css'
import { ChevronLeft, ChevronRight, MoreHorizontal } from 'react-feather'
import { cx } from 'class-variance-authority'
import Link, { LinkProps } from 'next/link'

interface PageButtonProps {
  pageNumber: number
  isActive: boolean
  href: LinkProps['href']
}

function PageButton({ pageNumber, isActive, href }: PageButtonProps) {
  return (
    <Link className={styles.button} data-active={isActive} href={href} data-disabled={isActive}>
      {pageNumber}
    </Link>
  )
}

function Ellipsis() {
  return <MoreHorizontal className={styles.more} size={24} />
}

export default function Pagination({
  currentPage: unfilteredCurrentPage,
  numberOfPages,
  createHref,
  className,
  ...props
}: LinkPaginationProps) {
  const currentPage = unfilteredCurrentPage <= numberOfPages && unfilteredCurrentPage > 0 ? unfilteredCurrentPage : 1
  const pages: ReactNode[] = []

  let startPage: number
  let stopPage: number

  if (numberOfPages <= 5) {
    startPage = 2
    stopPage = numberOfPages - 1
  } else if (currentPage < 4) {
    startPage = 2
    stopPage = Math.min(5, numberOfPages - 1)
  } else if (currentPage >= numberOfPages - 2) {
    startPage = numberOfPages - 4
    stopPage = numberOfPages - 1
  } else {
    startPage = currentPage - 2
    stopPage = currentPage + 2
  }

  for (let i = startPage; i <= stopPage; i++) {
    pages.push(<PageButton key={i} pageNumber={i} isActive={i === currentPage} href={createHref(i)} />)
  }

  return (
    <div className={cx(styles.pagination, className)} {...props}>
      <Link className={styles.button} data-disabled={currentPage === 1} href={createHref(currentPage - 1)}>
        <ChevronLeft className={styles.chevron} size={24} />
      </Link>

      <PageButton pageNumber={1} isActive={currentPage === 1} href={createHref(1)} />

      {startPage > 2 && (
        <Link className={cx(styles.button, styles.ellipsisButton)} href={createHref(currentPage - 3)}>
          <Ellipsis />
        </Link>
      )}

      {pages}

      {stopPage < numberOfPages - 1 && (
        <Link className={cx(styles.button, styles.ellipsisButton)} href={createHref(currentPage + 3)}>
          <Ellipsis />
        </Link>
      )}

      {numberOfPages > 1 && (
        <PageButton
          pageNumber={numberOfPages}
          isActive={currentPage === numberOfPages}
          href={createHref(numberOfPages)}
        />
      )}

      <Link className={styles.button} data-disabled={currentPage === numberOfPages} href={createHref(currentPage + 1)}>
        <ChevronRight className={styles.chevron} size={24} />
      </Link>
    </div>
  )
}
