'use client'

import { forwardRef, useRef } from 'react'
import { cx } from 'class-variance-authority'
import { textAreaStyle, TTextAreaProps } from '.'
import { captionStyle, labelStyle } from '../TextField'
import TextInputCounter from '../TextInputCounter'
import { useForkRef } from 'shared-utils'

/**
 * Textarea allow user input. The border should light up simply and clearly indicating which field the user is currently editing.
 */
const TextArea = forwardRef<HTMLTextAreaElement, TTextAreaProps>(function (props, ref) {
  const {
    variant = 'normal',
    disabled = false,
    className,
    wrapperProps,
    fieldType = 'outline',
    label,
    caption,
    rows = 4,
    maxLength,
    showMaxLength = true,
    value,
    captionIcon,
    captionClassName,
    ...rest
  } = props
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const handleRef = useForkRef(inputRef, ref)

  return (
    <label {...wrapperProps}>
      {label && <div className={labelStyle}>{label}</div>}
      <textarea
        {...rest}
        maxLength={maxLength}
        ref={handleRef}
        rows={rows}
        className={cx(
          textAreaStyle.base,
          fieldType === 'inline' && textAreaStyle.inline,
          fieldType === 'outline' && textAreaStyle.outline,
          variant === 'success' && !disabled && '!border-success500',
          variant === 'error' && !disabled && '!border-error500',
          className,
        )}
        disabled={disabled}
        value={value?.toString()}
      />
      <div className={cx('flex', caption ? 'justify-between' : 'justify-end')}>
        <div className={cx(Boolean(captionIcon) && 'flex flex-nowrap items-center')}>
          {!!captionIcon && <div className="mr-1 mt-2 h-3 w-3 shrink-0">{captionIcon}</div>}
          <div
            className={cx(
              captionClassName,
              captionStyle,
              variant === 'error' && 'text-error500',
              variant === 'success' && 'text-success500',
              variant === 'normal' && 'text-tertiary300',
              Boolean(captionIcon) && 'flex-grow',
            )}
          >
            {caption}
          </div>
        </div>
        {maxLength && showMaxLength && (
          <TextInputCounter
            maxLength={maxLength}
            inputRef={inputRef}
            variant={variant}
            initialLength={(value ?? rest.defaultValue)?.toString().length ?? 0}
          />
        )}
      </div>
    </label>
  )
})

export default TextArea
