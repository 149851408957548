'use client'
import { cx } from 'class-variance-authority'
import { TRadioProps } from './Radio.type'
import { forwardRef } from 'react'
import styles from './Radio.module.css'

const Radio = forwardRef<HTMLInputElement, TRadioProps>(function (props, ref) {
  const { disabled = false, className, children, size = 'large', wrapperProps, ...rest } = props

  return (
    <label {...wrapperProps} className={cx(styles.wrapper, wrapperProps?.className)} data-size={size}>
      <input {...rest} ref={ref} disabled={disabled} type="radio" className={cx(styles.input, className)} />
      {children && <span className={styles.caption}>{children}</span>}
    </label>
  )
})

export default Radio
