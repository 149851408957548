"use client"
import { RefObject, useEffect, useRef } from "react"

const EVENT = "mousedown"

function useClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T> | undefined,
  callback: (e: Event) => void
) {
  const listener = useRef<(this: Document, ev: MouseEvent) => void>(() => null)

  useEffect(() => {
    listener.current = (event: Event) => {
      if (!ref || !ref.current || ref.current.contains(event.target as Node)) {
        return
      }
      callback(event)
    }

    if (ref?.current) {
      document.addEventListener(EVENT, listener.current)
    } else {
      document.removeEventListener(EVENT, listener.current)
    }

    return () => {
      document.removeEventListener(EVENT, listener.current)
    }
  }, [ref, callback])
}

export default useClickOutside
