import { ChevronLeft, ChevronRight, ZoomIn, ZoomOut } from 'react-feather'

import PdfController from './PdfController'
import Button from '../Button'
import { TDocumentState, ZOOM_INDEXES } from './PdfViewer.types'

type TFooterAction = {
  actionBtnText?: string
  url: string
  onZoom: (action: 'minus' | 'plus') => () => void
  documentState: TDocumentState
  onChangePage: (action: 'minus' | 'plus') => () => void
  enableDownload?: boolean
}

const FooterAction = ({
  actionBtnText = 'Unduh Dokumen',
  onZoom,
  url,
  onChangePage,
  documentState,
  enableDownload = true,
}: TFooterAction) => {
  const handleOnClickBtn = () => {
    window.location.href = url
  }

  return (
    <div className="fixed bottom-0 z-10 w-full border-t border-tertiary50 bg-primary25">
      <div className="mx-auto flex w-full max-w-[1440px] justify-end gap-4 py-4 md:px-4 lg:px-10 xl:px-[128px]">
        <PdfController
          minusDisabled={documentState.page <= 1}
          minusIcon={<ChevronLeft className="h-5 w-5" />}
          onAction={onChangePage}
          plusDisabled={documentState.page === documentState.numPages}
          plusIcon={<ChevronRight className="h-5 w-5" />}
          text={`${documentState.page} dari ${documentState.numPages} halaman`}
          minusId="prev-page-btn"
          plusId="next-page-btn"
        />
        <PdfController
          minusDisabled={documentState.scaleIndex <= 0}
          minusIcon={<ZoomOut className="h-5 w-5" />}
          onAction={onZoom}
          plusDisabled={documentState.scaleIndex >= ZOOM_INDEXES.length - 1}
          plusIcon={<ZoomIn className="h-5 w-5" />}
          text={`${Math.floor((ZOOM_INDEXES[documentState.scaleIndex] ?? 1) * 100)}%`}
          minusId="zoom-out-btn"
          plusId="zoom-in-btn"
        />
        {enableDownload && (
          <Button id="download-btn" className="!px-11" onClick={handleOnClickBtn}>
            {actionBtnText}
          </Button>
        )}
      </div>
    </div>
  )
}

export default FooterAction
