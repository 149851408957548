// This is a temporary component. We should upgrade it in the next iteration,
// either by using an open-source library or building it from scratch.
// We need to implement a playback player so users can view the video at their desired minutes.

'use client'

import { useState, useRef } from 'react'
import { PlayCircle } from 'react-feather'
import { TVideoPlayerProps } from './VideoPlayer.type'
import { cx } from 'class-variance-authority'

type VideoPlayerProps = TVideoPlayerProps & {
  autoplay?: boolean
  controls?: boolean
  className?: string
  playCircleSize?: number
}

const VideoPlayer = ({
  src,
  autoplay = false,
  controls = false,
  className = '',
  playCircleSize = 80,
}: VideoPlayerProps) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(autoplay)
  const videoRef = useRef<HTMLVideoElement>(null)

  const togglePlay = () => {
    const video = videoRef.current
    if (!video) return

    if (video.paused) {
      video.play()
      setIsPlaying(true)
    } else {
      video.pause()
      setIsPlaying(false)
    }
  }

  return (
    <div className={cx('relative h-auto w-full', className)}>
      <video
        ref={videoRef}
        width="100%"
        height="100%"
        className="h-auto w-full"
        src={src}
        onClick={togglePlay}
        controls={controls}
      />
      {!isPlaying && (
        <button
          id="vide-player-play-button"
          onClick={togglePlay}
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        >
          <PlayCircle
            size={playCircleSize}
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-primary25"
          />
        </button>
      )}
    </div>
  )
}

export default VideoPlayer
