'use client'
import { SyntheticEvent, useState, useContext } from 'react'
import { cx } from 'class-variance-authority'
import * as Toast from '@radix-ui/react-toast'
import { X } from 'react-feather'

import { SnackbarContext } from './SnackbarContext'
import { TSnackbarOption, TSnackbarProviderProps, TSnackbarProps } from './Snackbar.type'
import styles from './Snackbar.module.css'

import Button from '../Button'

export function Snackbar(props: TSnackbarProps) {
  const { title, message, Icon, onButtonClick, actionButton, onClose, className, type, ...propsWrapper } = props

  const iconVariant = Icon ? 'icon' : 'hidden'
  const buttonVariant = !actionButton ? 'hidden' : actionButton === 'close' ? 'cross' : 'buttonWithText'
  const IconElement = iconVariant === 'icon' ? Icon && <Icon className={styles.icon} /> : null

  const ButtonElement =
    actionButton === 'close' ? (
      <button className={styles.crossButton} onClick={onButtonClick}>
        <X className={styles.crossIcon} stroke-width="1.5px" style={{ width: '24px', height: '24px' }} />
      </button>
    ) : actionButton ? (
      <Button className={cx(styles.buttonText)} variant="soft" onClick={onButtonClick}>
        {actionButton && actionButton?.title}
      </Button>
    ) : null

  const handleClose = (e: SyntheticEvent<HTMLLIElement>) => {
    if (e.target instanceof HTMLLIElement && e.target.dataset.state === 'close') {
      onClose()
    }
  }

  return (
    <Toast.Root className={styles.ToastRoot} onAnimationEnd={handleClose}>
      <div
        className={cx(className, styles.mainContainer)}
        data-variant={type}
        data-button-variant={buttonVariant}
        {...propsWrapper}
      >
        <span className={styles.iconContainer} style={{ display: iconVariant === 'hidden' ? 'none' : undefined }}>
          {IconElement}
        </span>
        <span className={styles.textContainer}>
          <Toast.Title className={cx(styles.title, !title && 'hidden')}>{title}</Toast.Title>
          <Toast.Description
            className={cx(styles.message, !message && 'hidden', title ? 'text-tertiary300' : 'text-tertiary500')}
          >
            {message}
          </Toast.Description>
        </span>
        <Toast.Close asChild={true}>{ButtonElement}</Toast.Close>
      </div>
    </Toast.Root>
  )
}

export default function SnackbarProvider({ children, duration = 3000, maxSnackbars = 5 }: TSnackbarProviderProps) {
  const [snackbars, setSnackbars] = useState<Array<{ snackbarOption: TSnackbarOption; index: number }>>([])
  const [counter, setCounter] = useState(0)

  const dequeue = (index?: number) => {
    setSnackbars([...snackbars.slice(0, index ?? 0), ...snackbars.slice((index ?? 0) + 1)])
  }

  const enqueue = (newSnackbar: TSnackbarOption) => {
    setSnackbars([
      ...snackbars.slice(snackbars.length >= maxSnackbars ? 1 : 0),
      { snackbarOption: newSnackbar, index: counter },
    ])
    setCounter(counter + 1)
  }

  return (
    <SnackbarContext.Provider value={{ enqueueSnackbar: enqueue, dequeueSnackbar: dequeue }}>
      <Toast.Provider duration={duration}>
        {children}
        {snackbars.map((snackbar, index) => {
          return (
            <Snackbar
              key={snackbar.index}
              {...snackbar.snackbarOption}
              onClose={() => {
                dequeue(index)
              }}
            />
          )
        })}
        <Toast.Viewport className={styles.ToastViewport} />
      </Toast.Provider>
    </SnackbarContext.Provider>
  )
}

export function useSnackbar() {
  const { enqueueSnackbar } = useContext(SnackbarContext)
  return { enqueueSnackbar }
}
