'use client'
import { format, isBefore, isValid, parse } from 'date-fns'
import { useRef, useState, KeyboardEvent, ChangeEvent, memo } from 'react'
import { DateRange, DayPicker, SelectRangeEventHandler } from 'react-day-picker'
import { usePopper } from 'react-popper'
import TextField from '../TextField'
import { Calendar } from 'react-feather'
import styles from '../DatePicker/DatePicker.module.css'
import dateRangePickerStyles from './DateRangePicker.module.css'
import 'react-day-picker/dist/style.css'
import { cx } from 'class-variance-authority'
import { TDateRangePickerPresetValue, TDateRangePickerProps } from './DateRangePicker.type'
import { id } from 'date-fns/locale'
import DateRangePickerPreset from './DateRangePickerPreset'
import useClickOutside from 'shared-utils/src/useClickOutside'
import equal from 'fast-deep-equal'
import { useDeepCompareEffect } from 'shared-utils'

function DateRangePicker(props: TDateRangePickerProps) {
  const {
    textFieldProps,
    disabled,
    formatDisplay = 'y-MM-dd',
    formatValue = 'y-MM-dd',
    value,
    onChange,
    toClassName,
    toInputClassName,
    withRangePickerPreset = true,
    onlyChangeToDate = false,
    separator,
    mustHaveInterval = false,
    onPopperClose,
    ...rest
  } = props
  const [isPopperOpen, setIsPopperOpen] = useState(false)

  const wrapperRef = useRef<HTMLDivElement>(null)
  const popperRef = useRef<HTMLDivElement>(null)
  const inputToRef = useRef<HTMLInputElement>(null)
  const inputFromRef = useRef<HTMLInputElement>(null)
  const [selectedRange, setSelectedRange] = useState<DateRange>()
  const [fromValue, setFromValue] = useState<string>('')
  const [toValue, setToValue] = useState<string>('')
  const [month, setMonth] = useState<Date>()

  const [dateRangePickerPreset, setDateRangePickerPreset] = useState<TDateRangePickerPresetValue>('custom')

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start',
  })

  const closePopper = () => {
    const dateFrom = fromValue ? parse(fromValue, formatValue, new Date(), { locale: id }) : undefined
    const dateTo = toValue ? parse(toValue, formatValue, new Date(), { locale: id }) : undefined

    if (
      !(selectedRange === undefined || selectedRange === undefined) &&
      (dateFrom !== selectedRange?.from || dateTo !== selectedRange?.to)
    ) {
      setSelectedRange({ from: dateFrom, to: dateTo })
      onChange?.({
        from: dateFrom,
        to: dateTo,
      })
      onPopperClose?.({
        from: selectedRange.from,
        to: selectedRange.to,
      })
    }

    setIsPopperOpen(false)
  }

  const handleInputOnClick = () => {
    setIsPopperOpen(true)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>, trigered: string) => {
    setDateRangePickerPreset('custom')
    if (trigered === 'from') {
      setFromValue(e?.target?.value)
      const date = parse(e.target.value, formatValue, new Date(), { locale: id })
      if (!isValid(date)) {
        const tempValue = { from: undefined, to: undefined }
        if (e.target?.value?.toString().length < 1) {
          handleRangeSelect(tempValue, new Date(), {}, undefined as any)
        }
        setSelectedRange(tempValue)
      }
    } else if (trigered === 'to') {
      setToValue(e.target.value)
      const date = parse(e.target.value, formatValue, new Date(), { locale: id })

      if (!isValid(date)) {
        const tempValue = { from: selectedRange?.from, to: undefined }
        if (e.target?.value?.toString().length < 1) {
          handleRangeSelect(tempValue, selectedRange?.from || new Date(), {}, undefined as any)
        }
        return setSelectedRange(tempValue)
      }
    }
  }

  const handleOnEnter = (e: KeyboardEvent<HTMLInputElement>, trigered: string) => {
    const dateFrom = fromValue ? parse(fromValue, formatValue, new Date(), { locale: id }) : undefined
    const dateTo = toValue ? parse(toValue, formatValue, new Date(), { locale: id }) : undefined

    const tempSelectFrom = trigered === 'from' && isValid(dateFrom) ? dateFrom : selectedRange?.from
    const tempSelectTo = trigered === 'to' && isValid(dateTo) ? dateTo : selectedRange?.to

    if (e.key === 'Enter' || e.key === 'Tab') {
      handleOnBlur(trigered)
      setSelectedRange({ from: tempSelectFrom, to: tempSelectTo })
      onChange?.({
        from: tempSelectFrom,
        to: tempSelectTo,
      })

      if (trigered === 'from') inputToRef.current?.focus()
      if (trigered === 'to') closePopper()
    }
  }

  const handleOnBlur = (trigered: string) => {
    const dateFromFormatted = parse(fromValue, formatValue, new Date(), { locale: id })
    const dateToFormated = parse(toValue, formatValue, new Date(), { locale: id })
    if ((trigered === 'from' && !isValid(dateFromFormatted)) || isBefore(dateToFormated, dateFromFormatted)) {
      setFromValue('')
      setToValue('')
      setSelectedRange({ from: undefined, to: undefined })
      onChange?.(undefined)
    }
    if (trigered === 'to' && !isValid(dateToFormated)) {
      setToValue('')
      onChange?.({ from: isValid(dateFromFormatted) ? dateFromFormatted : undefined, to: undefined })
    }
  }

  const handleRangeSelect: SelectRangeEventHandler = (range: DateRange | undefined) => {
    setSelectedRange(range)
    const hasInterval = range?.from?.toLocaleDateString() !== range?.to?.toLocaleDateString()

    if (mustHaveInterval && !hasInterval) {
      setSelectedRange({ from: undefined })
      setFromValue('')
      setToValue('')
    } else {
      const formattedRangeFrom = range?.from ? format(range.from, formatDisplay, { locale: id }) : ''
      setFromValue(formattedRangeFrom)

      const formattedRangeTo = range?.to ? format(range.to, formatDisplay, { locale: id }) : ''
      setToValue(formattedRangeTo)
    }

    onChange?.(range)
  }

  const handleDayClick = (day: Date) => {
    setSelectedRange({
      from: value?.from,
      to: day,
    })
    setToValue(format(day, formatDisplay, { locale: id }))
    onChange?.({
      from: value?.from,
      to: day,
    })
  }

  useDeepCompareEffect(() => {
    if (value) {
      handleRangeSelect(value, value.from || new Date(), {}, undefined as any)
      if (!value.from || !value.to) {
        setDateRangePickerPreset('custom')
      }
    }
  }, [value])

  useClickOutside(isPopperOpen ? wrapperRef : undefined, closePopper)

  return (
    <div ref={wrapperRef}>
      <div ref={popperRef}>
        <TextField
          {...textFieldProps}
          ref={inputFromRef}
          wrapperProps={{
            className: dateRangePickerStyles.inputFrom,
          }}
          onBlur={() => handleOnBlur('from')}
          onKeyDown={(e) => handleOnEnter(e, 'from')}
          disabled={disabled}
          iconLeft={() => <Calendar className="max-w-[18px]" />}
          value={fromValue}
          onChange={(e) => handleChange(e as ChangeEvent<HTMLInputElement>, 'from')}
          onClick={handleInputOnClick}
          addonRight={
            <div className={cx(dateRangePickerStyles.inputTo)}>
              {separator ?? 'Sampai'}
              <input
                ref={inputToRef}
                placeholder={textFieldProps?.toplaceholder ? textFieldProps.toplaceholder : textFieldProps?.placeholder}
                value={toValue}
                onChange={(e) => handleChange(e, 'to')}
                onClick={handleInputOnClick}
                onBlur={() => handleOnBlur('to')}
                onKeyDown={(e) => handleOnEnter(e, 'to')}
                className={toInputClassName}
              />
            </div>
          }
          addOnRightClassName={toClassName}
        />
      </div>
      {isPopperOpen && (
        <div
          tabIndex={-1}
          style={popper.styles.popper}
          {...popper.attributes.popper}
          className="z-10"
          ref={setPopperElement}
          role="dialog"
        >
          {withRangePickerPreset && (
            <DateRangePickerPreset
              value={dateRangePickerPreset}
              onChange={(value, dateRange) => {
                setDateRangePickerPreset(value)
                handleRangeSelect(dateRange, dateRange?.from || new Date(), {}, undefined as any)
              }}
            />
          )}
          <DayPicker
            {...rest}
            locale={id}
            weekStartsOn={1}
            numberOfMonths={2}
            disabled={disabled}
            onMonthChange={setMonth}
            month={month}
            defaultMonth={selectedRange?.from}
            className={cx(styles.datePicker, withRangePickerPreset && dateRangePickerStyles.dateRangePicker)}
            showOutsideDays
            mode="range"
            selected={selectedRange}
            onSelect={
              !onlyChangeToDate
                ? (...args) => {
                    setDateRangePickerPreset('custom')
                    handleRangeSelect(...args)
                  }
                : undefined
            }
            onDayClick={onlyChangeToDate ? handleDayClick : undefined}
          />
        </div>
      )}
    </div>
  )
}

export default memo(DateRangePicker, (prevProps, nextProps) => {
  return equal(prevProps, nextProps)
})
