'use client'

import { Content, Portal, Root, Trigger, Separator } from '@radix-ui/react-dropdown-menu'
import { TDropdownProps } from './Dropdown.type'

import styles from './Dropdown.module.css'
import { cx } from 'class-variance-authority'

const Dropdown = ({
  trigger,
  position = 'bottom',
  positionOffset = 8,
  onOpenChange,
  open,
  className,
  children,
  footer,
  variant = 'main',
  id,
  positionAlign = 'center',
  disabled,
}: TDropdownProps) => {
  return (
    <Root onOpenChange={onOpenChange} open={open}>
      <Trigger asChild disabled={disabled}>
        {trigger}
      </Trigger>
      <Portal>
        <Content
          defaultChecked={true}
          className={cx(styles.dropdownContent, className)}
          sideOffset={positionOffset}
          side={position}
          data-variant={variant}
          id={id}
          align={positionAlign}
        >
          {children}
          {footer && (
            <>
              <Separator className={styles.separator} />
              <div className={styles.footer}>{footer}</div>
            </>
          )}
        </Content>
      </Portal>
    </Root>
  )
}

export default Dropdown
