'use client'
import { cx } from 'class-variance-authority'
import { X } from 'react-feather'
import { TBadgeProps } from './Badge.type'
import styles from './Badge.module.css'

function Badge(props: TBadgeProps) {
  const {
    color = 'primary',
    size = 'medium',
    iconLeft: IconLeft,
    iconRight: IconRight,
    className,
    children,
    onClose,
    ...rest
  } = props

  const isClosable = onClose !== undefined

  return (
    <span
      {...rest}
      className={cx(className, styles.badge)}
      data-size={size}
      data-color={color}
      data-only-icon={Boolean(!children && (IconLeft || IconRight))}
    >
      {IconLeft && <IconLeft className={cx(Boolean(children) && styles.iconLeft)} />}
      {children}
      {isClosable && <X className={cx(Boolean(children) && styles.iconRight, styles.iconClose)} onClick={onClose} />}
      {IconRight && !isClosable && <IconRight className={styles.iconRight} />}
    </span>
  )
}

export default Badge
