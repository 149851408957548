'use client'
import { useState } from 'react'
import Image, { type StaticImageData } from 'next/image'
import { TImageWithFallbackProps } from './ImageWithFallback.type'

const ImageWithFallback = (props: TImageWithFallbackProps) => {
  const { src, fallbackSrc, ...rest } = props
  const [imgSrc, setImgSrc] = useState<string | StaticImageData>(src)
  const handleError = (): void => setImgSrc(fallbackSrc)
  return <Image {...rest} src={imgSrc} onError={handleError} />
}

export default ImageWithFallback
