'use client'

import { forwardRef, useRef } from 'react'
import { cx } from 'class-variance-authority'
import { TNPWPFieldProps } from '.'
import useForkRef from 'shared-utils/src/useForkRef'
import { NumberFormatBase, patternFormatter, usePatternFormat } from 'react-number-format'
import RawTextField from '../RawTextField/RawTextField'
import { inputStyle } from '../TextField'

export const formatNPWP = (data: string) => {
  const afterFormat = patternFormatter(data, {
    format: data.length > 15 ? '###.###.###.#-###.###' : '##.###.###.#-###.###',
  })
  return afterFormat
}

const NPWPField = forwardRef<HTMLInputElement, TNPWPFieldProps>(function (props, ref) {
  const {
    variant = 'normal',
    iconLeft: IconLeft,
    iconRight: IconRight,
    addonLeft,
    addonRight,
    disabled = false,
    size = 'medium',
    className,
    wrapperProps,
    fieldType = 'outline',
    label,
    caption,
    clearable,
    captionClassName,
    captionIcon,
    ...rest
  } = props

  const { getCaretBoundary, removeFormatting } = usePatternFormat({
    format: '###.###.###.#-###.###',
    mask: '_',
  })

  const inputRef = useRef<HTMLInputElement>()
  const handleRef = useForkRef(inputRef, ref)

  return (
    <RawTextField
      variant={variant}
      iconLeft={IconLeft}
      iconRight={IconRight}
      addonLeft={addonLeft}
      addonRight={addonRight}
      disabled={disabled}
      size={size}
      wrapperProps={wrapperProps}
      fieldType={fieldType}
      label={label}
      caption={caption}
      clearable={clearable}
      captionClassName={captionClassName}
      captionIcon={captionIcon}
      ref={handleRef}
    >
      <NumberFormatBase
        {...rest}
        getCaretBoundary={getCaretBoundary}
        removeFormatting={removeFormatting}
        getInputRef={handleRef}
        format={formatNPWP}
        className={cx(inputStyle, className)}
        disabled={disabled}
      />
    </RawTextField>
  )
})

export default NPWPField
