import { useEffect, useRef } from 'react'

const behavior = 'smooth'

const useBehaviourScroll = ({
  isPopperOpen,
  hoursValue,
  minuteValue,
}: {
  isPopperOpen: boolean
  hoursValue: string
  minuteValue: string
}) => {
  const refHour = useRef<HTMLDivElement>(null)
  const refMinute = useRef<HTMLDivElement>(null)

  useEffect(() => {
    refHour.current?.scrollIntoView({ behavior })
    const awaitScroll = setTimeout(() => {
      refMinute.current?.scrollIntoView({ behavior })
    }, 800)
    return () => clearTimeout(awaitScroll)
  }, [isPopperOpen])

  useEffect(() => {
    refHour.current?.scrollIntoView({ behavior })
  }, [hoursValue])

  useEffect(() => {
    refMinute.current?.scrollIntoView({ behavior })
  }, [minuteValue])

  return { refHour, refMinute }
}
export default useBehaviourScroll
