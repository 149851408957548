import { type HTMLProps, useEffect, useRef } from "react"

type TIndeterminateCheckboxProps = {
  indeterminate?: boolean
  type?: "checkbox" | "radio"
} & HTMLProps<HTMLInputElement>

export function IndeterminateCheckbox({
  className = "",
  checked,
  disabled,
  indeterminate,
  onChange,
  type = "checkbox",
}: TIndeterminateCheckboxProps) {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (ref?.current) {
      if (typeof indeterminate === "boolean") {
        ref.current.indeterminate = !checked && indeterminate
      }
    }
  }, [ref, indeterminate, checked])

  return (
    <input
      type={type}
      ref={ref}
      className={className + " cursor-pointer"}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
    />
  )
}
