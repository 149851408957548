"use client"

import { TGetSignedUrl } from "../types"
import { useEffect, useRef, useState } from "react"
import { AxiosError, type CancelTokenSource } from "axios"
import useGetUploadStatusV2 from "./useGetUploadStatusV2"
import { createMutation } from "react-query-kit"
import axiosProgress from "./axiosProgress"

export const FINISH_STATUS = [
  "UPLOAD_VALIDATION_FAILED",
  "UPLOAD_VIRUS_DETECTED",
  "UPLOAD_SUCCESS",
]

type TTimeout = {
  /**
   * timeout duration in ms
   */
  duration: number
  message: string
}

type TUseUploadProps<SignedUrlParam> = {
  onError?: (err: string) => void
  onSuccessUpload?: (signedUrl: TGetSignedUrl, file?: File) => void
  getSignedUrl: (param: SignedUrlParam) => Promise<TGetSignedUrl | undefined>
  errorMessage?: {
    validationFailed?: string
    virusDetected?: string
  }
  timeout?: TTimeout
  baseURl: string
}
const useUploadGcs = <SignedUrlParam>({
  onSuccessUpload,
  onError,
  getSignedUrl,
  errorMessage,
  timeout,
  baseURl,
}: TUseUploadProps<SignedUrlParam>) => {
  const source = useRef<CancelTokenSource>()
  const [percentage, setPercentage] = useState(0)
  const [file, setFile] = useState<File>()

  const { getUploadStatus, closeSocket } = useGetUploadStatusV2({
    onSuccessUpload,
    onError,
    errorMessage,
    updatePercentage: (dataPercentage) => {
      setPercentage(dataPercentage)
    },
  })

  const handleError = (err: string) => {
    if (onError) {
      onError(
        err || "Telah terjadi kesalahan, mohon ulangi dalam beberapa saat lagi"
      )
    }
    setPercentage(0)
  }

  const cancel = (message?: string) => {
    closeSocket()
    if (source.current) {
      source.current.cancel(message ?? "Operation canceled by the user.")
      source.current = undefined
    }
    setFile(undefined)
    setPercentage(0)
  }

  const {
    mutate: upload,
    status,
    data: signedUrlInfo,
    mutateAsync: uploadAsync,
    reset,
  } = createMutation<
    TGetSignedUrl | undefined,
    SignedUrlParam & { file?: File }
  >({
    mutationFn: async (params) => {
      handleInitializeUploadState(params.file)
      return await getSignedUrl(params)
    },
  })({
    onSuccess: async (result, variable) => {
      if (result?.signedUrl) {
        try {
          const axios = await import("axios")
          const startTime = Date.now()
          source.current = axios.default.CancelToken.source()

          let lastProgressPercentage = 0
          let progressTimeout: NodeJS.Timeout

          const resetTimeout = () => {
            if (progressTimeout) {
              clearTimeout(progressTimeout)
            }
            progressTimeout = setTimeout(() => {
              cancel(timeout?.message)
            }, timeout?.duration)
          }
          await axios.default.put(result.signedUrl, variable.file, {
            onUploadProgress(progressEvent) {
              const { progressPercentage } = axiosProgress(
                progressEvent,
                startTime,
                100
              )

              if (progressPercentage !== lastProgressPercentage) {
                lastProgressPercentage = progressPercentage
                setPercentage(Math.round(progressPercentage * 0.88))

                if (timeout?.duration) {
                  resetTimeout() // Reset timeout only if timeout is enabled
                }
              }
            },
            cancelToken: source?.current.token,
          })
          getUploadStatus({
            baseURl,
            signedUrl: result,
            file: variable.file,
          })
        } catch (err) {
          if (err instanceof AxiosError && err.code !== "ERR_CANCELED") {
            handleError(err.message)
          } else {
            handleError("")
          }
        }
      }
    },
    onError: (err) => {
      handleError(err.message)
    },
  })

  const handleInitializeUploadState = (paramFile?: File) => {
    setFile(paramFile)
    setPercentage(1)
  }

  useEffect(() => {
    window.addEventListener("beforeunload", closeSocket)
    return () => {
      window.addEventListener("beforeunload", closeSocket)
    }
  }, [])

  return {
    percentage,
    cancel,
    file,
    upload,
    status,
    signedUrlInfo,
    uploadAsync,
    reset,
  }
}

export default useUploadGcs
