import { cx } from 'class-variance-authority'
import { type ReactNode } from 'react'

type TPdfController = {
  minusDisabled: boolean
  plusDisabled: boolean
  minusIcon: ReactNode
  plusIcon: ReactNode
  onAction: (action: 'minus' | 'plus') => () => void
  text: string
  minusId: string
  plusId: string
}

const PdfController = ({
  minusDisabled,
  plusDisabled,
  onAction,
  minusIcon,
  plusIcon,
  text,
  minusId,
  plusId,
}: TPdfController) => {
  return (
    <div className="flex h-11 items-center gap-1 rounded-4 border border-tertiary50 px-[14px] py-4 text-base">
      <button
        disabled={minusDisabled}
        onClick={onAction('minus')}
        className={cx(minusDisabled ? 'cursor-not-allowed text-tertiary300' : 'text-black100')}
        id={minusId}
      >
        {minusIcon}
      </button>
      <span>{text}</span>
      <button
        disabled={plusDisabled}
        onClick={onAction('plus')}
        className={cx(plusDisabled ? 'cursor-not-allowed text-tertiary300' : 'text-black100')}
        id={plusId}
      >
        {plusIcon}
      </button>
    </div>
  )
}
export default PdfController
