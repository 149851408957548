'use client'
import * as radixSlider from '@radix-ui/react-slider'
import { useState } from 'react'
import { cx } from 'class-variance-authority'

import styles from '../Slider.module.css'
import { TSliderSingleThumbProps } from '../Slider.type'

const SingleThumb = (props: TSliderSingleThumbProps) => {
  type valueType = number
  const {
    numberIndicatorStyle = 'float',
    extractLabel,
    className,
    max = 100,
    defaultValue = 0,
    value,
    onValueChange,
    onValueCommit,
    ...wrapperProps
  } = props
  const isControlled = value !== undefined
  const [values, setValues] = useState<valueType>(defaultValue as valueType)

  const handleChange = (newValues: valueType) => {
    setValues(newValues)
  }

  const percentagePosition: valueType = ((isControlled ? value : values) * 100) / max
  const adjustedPosition: valueType = ((isControlled ? value : values) / max - 0.5) * 24

  return (
    <radixSlider.Root
      className={cx(styles.SliderRoot, className)}
      defaultValue={[defaultValue]}
      value={isControlled ? [value] : undefined}
      onValueChange={(value: [number]) => {
        if (!isControlled) handleChange(value[0])
        onValueChange?.(value[0])
      }}
      onValueCommit={(value: [number]) => {
        onValueCommit?.(value[0])
      }}
      data-type={numberIndicatorStyle}
      max={max}
      {...wrapperProps}
    >
      <radixSlider.Track className={styles.SliderTrack}>
        <radixSlider.Range
          className={styles.SliderRange}
          style={values / max < 0.9 ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 } : {}}
        />
      </radixSlider.Track>
      <radixSlider.Thumb className={styles.SliderThumb} aria-label="thumb" />
      <span
        className={styles.numberIndicatorContainer}
        style={{
          left: `calc(${percentagePosition}% - ${adjustedPosition}px)`,
        }}
      >
        <p className={styles.numberIndicator}>{extractLabel?.(isControlled ? value : values)}</p>
      </span>
    </radixSlider.Root>
  )
}

export default SingleThumb
