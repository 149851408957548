import { type AxiosProgressEvent } from "axios"

const axiosProgress = (
  progressEvent: AxiosProgressEvent,
  startTime: number,
  customMaxProgressPercentage?: number
) => {
  let newEstimatedTimeLeft = 0

  const loadedBytes = progressEvent.loaded
  const totalBytes = progressEvent.total || 100
  const maxProgressPercentage = customMaxProgressPercentage || 70
  const originalPercentage = (loadedBytes / totalBytes) * 100
  const progressPercentage = Math.round(
    originalPercentage * (maxProgressPercentage / 100)
  )

  const currentTime = Date.now()
  const elapsedMilliseconds = currentTime - startTime
  const uploadSpeedBytesPerMs = loadedBytes / elapsedMilliseconds

  if (uploadSpeedBytesPerMs > 0) {
    const remainingBytes = totalBytes - loadedBytes
    newEstimatedTimeLeft = remainingBytes / uploadSpeedBytesPerMs
  }

  const estimatedSecondsLeft = Math.ceil(newEstimatedTimeLeft / 1000)
  const estimatedMinutesLeft = Math.ceil(estimatedSecondsLeft / 60)
  const estimatedHoursLeft = Math.floor(estimatedMinutesLeft / 60)

  return {
    progressPercentage,
    estimatedSecondsLeft,
    estimatedMinutesLeft,
    estimatedHoursLeft,
  }
}

export default axiosProgress
