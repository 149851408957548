import Image from 'next/image'

type TImageViewer = {
  url: string
}

const ImageViewer = ({ url }: TImageViewer) => {
  return (
    <div className="container mx-auto mt-6 px-4">
      <div className="mx-auto w-4/5 max-w-[80vw]">
        <Image
          src={url}
          alt="Description"
          width={0}
          height={0}
          sizes="80vw"
          className="h-auto w-auto"
          style={{ height: 'auto', width: 'auto' }}
          priority
        />
      </div>
    </div>
  )
}

export default ImageViewer
