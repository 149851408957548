import { type Editor } from '@tiptap/react'
import { cx } from 'class-variance-authority'
import {
  Bold as IconBold,
  Underline as IconUnderline,
  Italic as IconItalic,
  List as IconBulletList,
} from 'react-feather'

import Toggle from './Toggle'

type TToolbarProps = {
  editor: Editor
  isError: boolean
}
const Toolbar = ({ editor, isError }: TToolbarProps) => {
  if (!editor) {
    return null
  }

  return (
    <div
      className={cx(
        isError
          ? 'border-error500 group-focus-within:border-error500'
          : 'border-tertiary100 group-focus-within:border-tertiary200',
        'focus flex items-center gap-3 border-b border-tertiary100 p-2',
      )}
    >
      <Toggle
        onClick={() => editor.chain().focus().toggleBold().run()}
        isActive={editor.isActive('bold')}
        icon={IconBold}
      />
      <Toggle
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        isActive={editor.isActive('underline')}
        icon={IconUnderline}
      />
      <Toggle
        onClick={() => editor.chain().focus().toggleItalic().run()}
        isActive={editor.isActive('italic')}
        icon={IconItalic}
      />
      <Toggle
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        isActive={editor.isActive('bulletList')}
        icon={IconBulletList}
      />
    </div>
  )
}

export default Toolbar
