"use client"

import { TGetSignedUrl, TSocketMessage } from "../types"
import { useEffect, useRef, useState } from "react"
import { AxiosError, type CancelTokenSource } from "axios"
import { GTP_TYK_WS_URL } from "../constants/api"
import { createMutation } from "react-query-kit"
import axiosProgress from "./axiosProgress"

export const FINISH_STATUS = [
  "UPLOAD_VALIDATION_FAILED",
  "UPLOAD_VIRUS_DETECTED",
  "UPLOAD_SUCCESS",
]

const SCANNING_STATUS = {
  INITIATE_UPLOAD: 92,
  UPLOAD_ON_METADATA_VALIDATION: 95,
  UPLOAD_ON_MALWARE_SCANNING: 98,
}

type TTimeout = {
  /**
   * timeout duration in ms
   */
  duration: number
  message: string
}

type TUseUploadProps<SignedUrlParam> = {
  onError?: (err: string) => void
  onSuccessUpload?: (signedUrl: TGetSignedUrl, file?: File) => void
  getSignedUrl: (param: SignedUrlParam) => Promise<TGetSignedUrl | undefined>
  errorMessage?: {
    validationFailed?: string
    virusDetected?: string
  }
  timeout?: TTimeout
}
const useUploadGcs = <SignedUrlParam>({
  onSuccessUpload,
  onError,
  getSignedUrl,
  errorMessage,
  timeout,
}: TUseUploadProps<SignedUrlParam>) => {
  const websocket = useRef<WebSocket>()
  const source = useRef<CancelTokenSource>()
  const [percentage, setPercentage] = useState(0)
  const [file, setFile] = useState<File>()

  const handleError = (err: string) => {
    if (onError) {
      onError(
        err || "Telah terjadi kesalahan, mohon ulangi dalam beberapa saat lagi"
      )
    }
    setPercentage(0)
  }

  const closeSocket = () => {
    if (
      websocket.current &&
      websocket.current.readyState === websocket.current.OPEN
    ) {
      websocket.current.send(JSON.stringify({ id: "1", type: "stop" }))
      websocket.current.close()
      websocket.current = undefined
    }
  }

  const cancel = (message?: string) => {
    closeSocket()
    if (source.current) {
      source.current.cancel(message ?? "Operation canceled by the user.")
      source.current = undefined
    }
    setPercentage(0)
  }

  const createSocket = (signedUrlParam: TGetSignedUrl, file?: File) => {
    websocket.current = new WebSocket(GTP_TYK_WS_URL, "graphql-ws")

    websocket.current.onopen = () => {
      const connectionInit = JSON.stringify({ type: "connection_init" })
      const getUploadStatusGql = JSON.stringify({
        id: "1",
        type: "start",
        payload: {
          query: `subscription SS {\n  getUploadStatus(input: {token: "${signedUrlParam.token}"})\n}\n`,
          operationName: "SS",
        },
      })
      if (websocket?.current?.readyState === WebSocket.OPEN) {
        websocket?.current?.send(connectionInit)
        websocket?.current?.send(getUploadStatusGql)
      } else {
        websocket?.current?.addEventListener("open", () => {
          websocket?.current?.send(connectionInit)
          websocket?.current?.send(getUploadStatusGql)
        })
      }
    }

    websocket.current.onmessage = (event: MessageEvent<string>) => {
      const data = JSON.parse(event.data) as TSocketMessage
      const status = data?.payload?.data?.getUploadStatus || ""

      if (!FINISH_STATUS.includes(status) || !websocket.current) {
        setPercentage(
          (prev) =>
            SCANNING_STATUS?.[status as keyof typeof SCANNING_STATUS] || prev
        )

        return
      }
      switch (status) {
        case FINISH_STATUS[2]:
          setPercentage(0)
          onSuccessUpload && onSuccessUpload(signedUrlParam, file)
          break
        case FINISH_STATUS[1]:
          setPercentage(0)
          handleError(errorMessage?.virusDetected ?? "")
          break
        case FINISH_STATUS[0]:
          setPercentage(0)
          handleError(errorMessage?.validationFailed ?? "")
          break
        default:
          handleError("")
          break
      }
      closeSocket()
    }

    websocket.current.onerror = () => {
      handleError("")
      setPercentage(0)
      closeSocket()
    }
  }

  const {
    mutate: upload,
    status,
    data: signedUrlInfo,
    mutateAsync: uploadAsync,
    reset,
  } = createMutation<
    TGetSignedUrl | undefined,
    SignedUrlParam & { file?: File }
  >({
    mutationFn: async (params) => {
      handleInitializeUploadState(params.file)
      return await getSignedUrl(params)
    },
  })({
    onSuccess: async (result, variable) => {
      if (result?.signedUrl) {
        try {
          const axios = await import("axios")
          const startTime = Date.now()
          source.current = axios.default.CancelToken.source()

          let lastProgressPercentage = 0
          let progressTimeout: NodeJS.Timeout

          const resetTimeout = () => {
            if (progressTimeout) {
              clearTimeout(progressTimeout)
            }
            progressTimeout = setTimeout(() => {
              cancel(timeout?.message)
            }, timeout?.duration)
          }
          await axios.default.put(result.signedUrl, variable.file, {
            onUploadProgress(progressEvent) {
              const { progressPercentage } = axiosProgress(
                progressEvent,
                startTime,
                100
              )

              if (progressPercentage !== lastProgressPercentage) {
                lastProgressPercentage = progressPercentage
                setPercentage(Math.round(progressPercentage * 0.88))

                if (timeout?.duration) {
                  resetTimeout() // Reset timeout only if timeout is enabled
                }
              }
            },
            cancelToken: source?.current.token,
          })
          createSocket(result, variable.file)
        } catch (err) {
          if (err instanceof AxiosError && err.code !== "ERR_CANCELED") {
            handleError(err.message)
          } else {
            handleError("")
          }
        }
      }
    },
    onError: (err) => {
      handleError(err.message)
    },
  })

  const handleInitializeUploadState = (paramFile?: File) => {
    setFile(paramFile)
    setPercentage(1)
  }

  useEffect(() => {
    window.addEventListener("beforeunload", closeSocket)
    return () => {
      window.addEventListener("beforeunload", closeSocket)
    }
  }, [])

  return {
    percentage,
    cancel,
    file,
    upload,
    status,
    signedUrlInfo,
    uploadAsync,
    reset,
  }
}

export default useUploadGcs
