'use client'
import { cx } from 'class-variance-authority'
import { TSwitchProps } from './Switch.type'
import { forwardRef } from 'react'
import styles from './Switch.module.css'

const Switch = forwardRef<HTMLInputElement, TSwitchProps>(function (props, ref) {
  const {
    disabled = false,
    checked,
    className,
    children,
    size = 'large',
    wrapperProps,
    variant = 'secondary',
    ...rest
  } = props

  return (
    <label {...wrapperProps} className={cx(styles.switch, wrapperProps?.className)} data-size={size}>
      <input
        {...rest}
        checked={checked}
        ref={ref}
        disabled={disabled}
        type="checkbox"
        className={cx(styles.input, className)}
      />
      <span className={cx(variant === 'primary' ? styles.sliderPrimary : styles.slider)} />
      {children && <span className={styles.caption}>{children}</span>}
    </label>
  )
})

export default Switch
