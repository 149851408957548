import { TDateRangePickerPresetValue } from './DateRangePicker.type'
import { DateRange } from 'react-day-picker'
import {
  subMonths,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  subWeeks,
  startOfDay,
  endOfDay,
  subDays,
  startOfYear,
  endOfYear,
} from 'date-fns'

export const presetDateRangeOptions: { value: TDateRangePickerPresetValue; label: string }[] = [
  {
    value: 'custom',
    label: 'Custom',
  },
  {
    value: 'today',
    label: 'Hari ini',
  },
  {
    value: 'yesterday',
    label: 'Kemarin',
  },
  {
    value: 'thisWeek',
    label: 'Minggu ini',
  },
  {
    value: 'lastWeek',
    label: 'Minggu lalu',
  },
  {
    value: 'thisMonth',
    label: 'Bulan ini',
  },
  {
    value: 'lastMonth',
    label: 'Bulan lalu',
  },
  {
    value: 'thisYear',
    label: 'Tahun ini',
  },
]

export function generatePresetDateRange(value: TDateRangePickerPresetValue): DateRange | undefined {
  const today = new Date()

  if (value === 'lastMonth') {
    return {
      from: startOfMonth(subMonths(today, 1)),
      to: endOfMonth(subMonths(today, 1)),
    }
  } else if (value === 'thisMonth') {
    return {
      from: startOfMonth(today),
      to: endOfMonth(today),
    }
  } else if (value === 'lastWeek') {
    return {
      from: startOfWeek(subWeeks(today, 1), { weekStartsOn: 1 }),
      to: endOfWeek(subWeeks(today, 1), { weekStartsOn: 1 }),
    }
  } else if (value === 'thisWeek') {
    return {
      from: startOfWeek(today, { weekStartsOn: 1 }),
      to: endOfWeek(today, { weekStartsOn: 1 }),
    }
  } else if (value === 'today') {
    return {
      from: startOfDay(today),
      to: endOfDay(today),
    }
  } else if (value === 'yesterday') {
    return {
      from: startOfDay(subDays(today, 1)),
      to: endOfDay(subDays(today, 1)),
    }
  } else if (value === 'thisYear') {
    return {
      from: startOfYear(today),
      to: endOfYear(today),
    }
  }

  return undefined
}
