'use client'

import { useEffect, useRef, useState } from 'react'
import { Clock, AlertTriangle } from 'react-feather'
import Button from '../Button'
import TextField from '../TextField'

import ItemPicker from './ItemPicker'
import { HOURS, MINUTES } from './constants'
import { getFormattedHour, getFormattedMinute } from './helpers/getFormattedHourAndMinute'
import useBehaviourScroll from './hooks/useBehaviorScroll'
import { useClickOutside } from 'shared-utils'
import { TTimePickerProps } from './TimePicker.type'

const TimePicker = ({
  title,
  placeholder,
  value,
  onSelect,
  reset,
  errorMessage,
  separator = ':',
  useScroll = false,
  id,
  disabled,
}: TTimePickerProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isPopperOpen, setIsPopperOpen] = useState(false)
  const [hoursValue, setHoursValue] = useState(getFormattedHour(value))
  const [minuteValue, setMinuteValue] = useState(getFormattedMinute(value))

  const inputValue = !!hoursValue && !!minuteValue ? `${hoursValue} ${separator} ${minuteValue}` : ''

  const handleOpenPopper = () => {
    setIsPopperOpen(true)
    if (!value) {
      setHoursValue('00')
      setMinuteValue('00')
    }
  }

  const handleOnClickField = () => {
    handleOpenPopper()
  }

  const handleClosePopper = (type: 'submit' | 'close') => {
    if (type === 'submit' && !inputValue) return

    setHoursValue((prev) => (type === 'close' ? getFormattedHour(value) : prev))
    setMinuteValue((prev) => (type === 'close' ? getFormattedMinute(value) : prev))
    onSelect?.(
      type === 'close'
        ? {
            clock: value ? inputValue : '',
          }
        : {
            clock: inputValue,
          },
    )
    setIsPopperOpen(false)
  }

  useClickOutside(isPopperOpen ? containerRef : undefined, () => {
    setIsPopperOpen(false)
    setHoursValue(getFormattedHour(value))
    setMinuteValue(getFormattedHour(value))
  })

  // To set default value
  useEffect(() => {
    if (value) {
      setHoursValue(getFormattedHour(value))
      setMinuteValue(getFormattedMinute(value))
    }
  }, [value])

  useEffect(() => {
    if (reset) {
      setHoursValue('')
      setMinuteValue('')
    }
  }, [reset])

  const { refHour, refMinute } = useBehaviourScroll({
    hoursValue,
    isPopperOpen,
    minuteValue,
  })

  return (
    <div className="relative h-full w-full border-tertiary50" ref={containerRef}>
      <TextField
        id={id}
        value={inputValue}
        iconRight={Clock}
        label={title}
        onClick={handleOnClickField}
        placeholder={placeholder}
        readOnly
        caption={errorMessage}
        variant={errorMessage ? 'error' : 'normal'}
        className="cursor-pointer"
        captionIcon={<AlertTriangle className="text-primary500" size={12} />}
        disabled={disabled}
      />
      {isPopperOpen && (
        <div className="absolute z-[999] mt-2 flex h-27  w-full flex-col justify-between overflow-visible rounded-2 border border-primary50 bg-primary25">
          <div id="time" className="grid grid-cols-2 p-4">
            <section className="max-h-[210px] snap-y overflow-y-auto pr-5">
              {HOURS.map((item, index) => (
                <ItemPicker
                  key={`hour-${index}`}
                  item={item}
                  onClick={() => {
                    setHoursValue(item)
                  }}
                  tipe="hour"
                  hoursValue={hoursValue}
                  refItem={useScroll && item === hoursValue ? refHour : undefined}
                />
              ))}
            </section>
            <div className="absolute left-1/2 top-[-1px] z-50 h-[247px] border-l border-l-tertiary50"></div>
            <section className="max-h-[210px] snap-y overflow-y-auto pl-5">
              {MINUTES.map((item, index) => (
                <ItemPicker
                  key={`minute-${index}`}
                  item={item}
                  onClick={() => setMinuteValue(item)}
                  tipe="minute"
                  minuteValue={minuteValue}
                  refItem={useScroll && item === minuteValue ? refMinute : undefined}
                />
              ))}
            </section>
          </div>
          <div className="flex justify-between border-t border-t-tertiary50 p-4">
            <Button variant="outline2" id="btn-modal-cancel-time" onClick={() => handleClosePopper('close')}>
              Batal
            </Button>

            <Button id="btn-modal-save-time" onClick={() => handleClosePopper('submit')}>
              Simpan
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default TimePicker
